var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        {
          staticClass: "table_head",
          attrs: { justify: "center", type: "flex" }
        },
        [
          _c("a-col", { attrs: { md: 18, sm: 4, xs: 24 } }, [
            _c("h1", [_vm._v("Liste des visites")])
          ]),
          _c(
            "a-col",
            { attrs: { md: 18, sm: 4, xs: 24 } },
            [
              _c("ListeVisites", {
                attrs: {
                  "data-source": _vm.visites,
                  processing: _vm.processing
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }