<template>
    <div>
        <a-row class="table_head" justify="center" type="flex">
            <a-col :md="18" :sm="4" :xs="24">
                <h1>Liste des visites</h1>
            </a-col>
            <a-col :md="18" :sm="4" :xs="24">
                <ListeVisites :data-source="visites" :processing="processing"/>
            </a-col>
        </a-row>
    </div>
</template>

<script>
    import ListeVisites from "@/components/common/demandes/ListeVisites";
    import {mapActions, mapState} from "vuex";


    export default {
        name: "VisitesIndex",
        components: {
            ListeVisites
        },
        created() {
            this.id = this.$route.params["id"];
            this.fetchData();
        },
        data() {
            return {
                loading: false,
                visible: false,
                processing: false,
                visiteModalVisible: false,
                visiteValidationModalVisible: false,
                decisionFormProcessing: false,
                selectedVisite: null,
                id: null,
                newVisiteModalKey: 0
            };
        },

        computed: {
            ...mapState({
                user: (state) => state.adherents.selected_adherent,
                visites: (state) => state.visites.visites,
            }),
        },

        methods: {

            fetchData(q = '') {
                this.startProcessing();
                this.getVisites({'id': this.id})
                    .finally(() => this.stopProcessing())
            }
            ,
            startProcessing() {
                this.processing = true;
            }
            ,
            search(value) {
                this.fetchData(value)
            }
            ,
            stopProcessing() {
                this.processing = false
            },
            ...mapActions({
                getVisites: "fetchVisitesOfDemandeById",
                add: "storeVisite",
                edit: "fetchVisiteById",
                updateVisiteRapport: "updateVisiteRapport",
                deleteVisiteFile: "deleteVisiteFile",
                update: "updateVisite",
                delete: "destroyVisite",
            }),
        }
    }
</script>